import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ProductDetailsService } from '../../../../SGRE-custom/product-details-page/services/product-details-service';
import { Subject, map, of, switchMap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { StorageService } from '../../../services/storage.service';
import { Reference } from '../../../../SGRE-custom/product-details-page/models/product-references';
import { Options, ProductVariant } from '../../../../SGRE-custom/product-details-page/models/product-info';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-pdp-alterante-product',
  templateUrl: './pdp-alterante-product.component.html',
  styleUrl: './pdp-alterante-product.component.scss',
})
export class PdpAlteranteProductComponent implements OnInit {

  roleFlag: boolean;
  referenceData$: Observable<Reference[]>;
  unsubscribe$ = new Subject<void>();
  emptyImagePath: string = '../../../../assets/images_and_icons/image1.jpg';

  constructor(
    private productsService: ProductDetailsService,
    private storageService: StorageService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.roleCheck(this.storageService.userRoles);
    this.referenceData$ = this.productsService.pCode$.pipe(
      switchMap((pCodeVal: string) => (pCodeVal?.length > 0) ? this.productsService.getReferences(pCodeVal) : ''),
      map((data) => {
        let refData: Reference[] = data?.references?.filter((obj) => obj?.referenceType === 'SIMILAR');
        for (const refObj of refData) {
          if (refObj?.target?.variantOptions) {
            let obj = this.getBaseOrVariantOptions(refObj);
            if (obj) {
              let availbleQty = this.getAvailableQty(obj?.code);
              obj.quantityValue = this.globalService.fetchProductQuantityValue(data, availbleQty);
              obj.isMaxLmtReached = (availbleQty >= (obj?.maxQuantity ? obj.maxQuantity : 1000));
            }
          }
        }
        return refData;
      })
    );
  }

  roleCheck(roles: string[] | string) {
    if (roles?.length > 0) {
      this.roleFlag =
        typeof roles === 'string'
          ? JSON.parse(roles).includes('Requester')
          : roles.includes('Requester');
    }
  }

  getAvailableQty(productId: string): number {
    let cardDetails = this.storageService.activeCart;
    let productDetails = cardDetails?.entries?.filter(
      (item) => item.product.baseProduct === productId
    );
    let quantity = productDetails?.at(0)?.quantity
      ? productDetails?.at(0)?.quantity
      : 0;
    return quantity;
  }

  getProductUrl(urlVal: string): string {
    return this.globalService.getProductUrl(urlVal);
  }

  getImageUrl(data: any): string | null {
    const imageUrl = data?.target?.images?.find((val) => val.imageType === 'PRIMARY')?.url;
    return imageUrl
      ? environment.siteUrls.getBackofficeUrl + imageUrl.split('/medias/')?.at(1)
      : this.emptyImagePath;
  }

  getBaseOrVariantOptions(data: Reference): Options | null {
    return (data?.target?.baseOptions?.length > 0)
      ? data.target.baseOptions.at(0)?.options?.find((val) => val.code.endsWith(ProductVariant.NEW))
      : data.target.variantOptions?.find((val) => val.code.endsWith(ProductVariant.NEW));
  }

  getProductNumber(data: Reference): string {
    let pNumber: string = '';
    pNumber = (data?.target?.baseProduct) ? data.target.baseProduct : data.target.code;
    if (pNumber?.length > 0 && (pNumber.endsWith(ProductVariant.NEW) || pNumber.endsWith(ProductVariant.REFURBISHED))) {
      pNumber = pNumber.slice(0, -2);
    }
    return pNumber;
  }

  checkPriceData(data: Reference): boolean {
    let flag = false;
    let obj = this.getBaseOrVariantOptions(data);
    if (obj) {
      flag = obj && obj?.quotationFlag === false && obj?.priceData?.value > 0;
    }
    return flag;
  }

  showAddToCart(data: Reference): boolean {
    let flag = false;
    let obj = this.getBaseOrVariantOptions(data);
    if (obj) {
      flag = obj && obj?.obsoleteFlag === false && this.roleFlag;
    }
    return flag;
  }

  getCode(data: Reference) {
    let str: string = null;
    let obj = this.getBaseOrVariantOptions(data);
    if (obj) {
      str = obj && obj.code ? obj.code : null;
    }
    return str;
  }

  checkStep(data: Reference): number | null {
    let num: number = null;
    let obj = this.getBaseOrVariantOptions(data);
    if (obj) {
      num = obj && obj.interval ? obj.interval : 1;
    }
    return num;
  }

  checkMinQtyAndValue(data: Reference): number | null {
    let num: number = null;
    let obj = this.getBaseOrVariantOptions(data);
    let availbleQty = this.getAvailableQty(obj?.code);
    if (obj) {
      num = this.globalService.fetchProductQuantityValue(obj, availbleQty);
    }
    return num;
  }

  checkMaxQty(data: Reference, codeVal: string): number | null {
    let num: number = null;
    let obj = this.getBaseOrVariantOptions(data);
    if (obj) {
      let availbleQty = this.getAvailableQty(codeVal);
      const maxQtyLimit = obj?.maxQuantity ? obj.maxQuantity : 1000;
      obj.maxQuantity = availbleQty ? (maxQtyLimit - availbleQty) : maxQtyLimit;
      num = obj.maxQuantity;
    }
    return num;
  }

  checkMaxLimit(quantityVal: number): number {
    let num: number = null;
    num = quantityVal ? quantityVal : 1000;
    return num;
  }

  checkIsMaxLmtReached(data: Reference, codeVal: string) {
    let flag = false;
    let obj = this.getBaseOrVariantOptions(data);
    if (obj) {
      let availbleQty = this.getAvailableQty(codeVal);
      flag = obj && (availbleQty >= (obj?.maxQuantity ? obj.maxQuantity : 1000));
    }
    return of(flag);
  }

  checkButtonName(data): string | null {
    let str: string = null;
    let obj = this.getBaseOrVariantOptions(data);
    if (obj) {
      str = obj && obj.quotationFlag ? 'requestQuotation' : 'addtoCart';
    }
    return str;
  }
}
