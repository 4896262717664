import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  Column,
  ColumnType,
} from '../../../../SGRE-shared/models/tableConfiguration';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { UserProfileService } from '../../services/user-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../SGRE-shared/services/api.service';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { MyProfileService } from '../../../my-profile/services/my-profile.service';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Subject, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { isNotNullable, LanguageService } from '@spartacus/core';
import { isEqual } from 'lodash';
import { ExportCsvFileService, ExportFileOptions } from '@spartacus/storefront';

@Component({
  selector: 'app-profile-table',
  templateUrl: './profile-table.component.html',
  styleUrl: './profile-table.component.scss',
})
export class ProfileTableComponent implements OnInit, OnDestroy {
  isCheckedToggle: boolean = true;
  albums: any = [];
  public isEdit: boolean = false;
  public toggleRequest: boolean = false;
  public updatedCutomers: any[] = [];
  public columns: Column[];
  public columns1: Column[];
  public columns2: Column[];
  public columnType: any[];
  public cartsList: any[] = [];
  formArrayVal: any = [];
  legalentities: any = [];
  revokAllFlag: boolean = false;
  isFromRoleView: boolean;
  userRolesSGRE: boolean = false;
  userRolesCustomer: boolean = false;
  public role: string = '';
  requestArray: any = [];
  public userRoles: any[];
  legalEntity: any;
  user: any;
  date: any;
  userSGRE: boolean = false;
  dateVal: any;
  adminToggle: boolean = true;
  csvdata: any;
  csvdatamodification: any = [];
  legalEntityName: any[] = [];
  arrayFlag1: boolean = false;
  editMode: boolean;
  isShowCheckBox: boolean;
  cartsListPending: any = [];
  internal: boolean = false;
  selectedCity: any | undefined;
  public entityList: any[] = [];
  tempData: any = [];
  loginData: any[];
  toggoleAdmin: boolean = false;
  adminToggleFlag: boolean = false;
  pendingData: any[] = [];
  pendingFlag: boolean = false;
  unsubscribe$ = new Subject<void>();
  filteredTableData: any = [];
  translations: any = {
    es: {
      LegalEntityId: 'Legal Entity Id',
      LegalEntityName: 'Legal Entity Name',
      Requester: 'Requester',
      CustomerAdmin: 'Customer Admin',
      Purchaser: 'Purchaser',
      Company: 'Company',
      SalesRep: 'Sales Rep'
    },
    en: {
      LegalEntityId: 'Legal Entity Id',
      LegalEntityName: 'Legal Entity Name',
      Requester: 'Requester',
      CustomerAdmin: 'Customer Admin',
      Purchaser: 'Purchaser',
      Company: 'Company',
      SalesRep: 'Sales Rep'
    },
    de: {
      LegalEntityId: 'Legal Entity Id',
      LegalEntityName: 'Legal Entity Name',
      Requester: 'Requester',
      CustomerAdmin: 'Customer Admin',
      Purchaser: 'Purchaser',
      Company: 'Company',
      SalesRep: 'Sales Rep'
    }
  };

  constructor(
    private router: Router,
    public userProfileService: UserProfileService,
    public storageService: StorageService,
    private apiService: ApiService,
    private changeRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    public myProfileService: MyProfileService,
    public globalService: GlobalService,
    private languageService: LanguageService,
    private csvExportService: ExportCsvFileService) { }

  ngOnInit() {
    this.getsessionvalues();
    this.roleCheck(this.storageService.userRoles);
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: params => {
          if (this.storageService.userProfileData === 'access') {
            this.legalEntity = params['legalEntity'];
            this.user = params['user'];
            this.dateVal = params['date']
            let dat = (this.dateVal).replaceAll(":", "%3A")
            this.date = (dat).replaceAll("+", "%2B")
            let paramValue = {
              legalEntity: this.legalEntity,
              userId: this.user,
              date: this.date
            }
            this.userProfileService.getPendingRequestData(paramValue);
          }
          else {
            this.user = params['user'] ? params['user'] : this.globalService.getUserId();
          }
        }
      })
    this.getUserProfileData();
  }

  translateArray(arr: string[], lang: 'en' | 'es' | 'de'): string[] {
    return arr.map(value => this.translations[lang][value] || value);
  }

  getUserProfileData() {
    this.internal = false;
    this.adminToggleFlag = false;
    this.cartsList = [];
    this.loginData = [];
    this.globalService.loadingSubject.next(true);

    this.userProfileService?.checkRole$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => (this.internal = data));

    this.userProfileService.profileData$
      .pipe(
        filter(isNotNullable),
        distinctUntilChanged(isEqual),
        takeUntil(this.unsubscribe$)
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data: any) => {
          this.tempData = data;
          this.getLoginData(this.tempData);
        },
        error: (err) => { this.globalService.loadingSubject.next(false) }
      });
  }

  getLoginData(dataval) {
    let paramObj = {
      fields: 'FULL',
      currentPage: 0,
      pageSize: 200,
      userID: this.globalService.getUserId()
    };
    if (dataval?.length > 0) {
      this.userSGRE = dataval[0].roles.some((val) => val === 'SGRE Admin');
    }
    if (this.internal) {
      this.adminToggleFlag = this.userSGRE;
    }
    this.apiService.getlegalentities(paramObj, {})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data) => {
          this.loginData = data.legalEntities;
          if (dataval?.length > 0) {
            this.getCustomerAdminData(this.loginData, dataval);
          } else {
            this.getLoginDataTable(this.loginData);
          }
        },
        error: (err) => { this.globalService.loadingSubject.next(false) }
      });
  }

  getLoginDataTable(data) {
    this.cartsList = [];
    let value;
    if (this.role === 'SGRE Admin') {
      this.cartsList = [];
      this.cartsList = this.cartsList.splice(0);
      this.changeRef.detectChanges();
      this.createTableConfiguration(this.cartsList);
    } else {
      data.map((obj) => {
        value = {
          legalEntityId: obj.uid,
          LegalEntity: obj.name,
          requester: false,
          customerAdmin: false,
          approver: false,
          salesRep: false,
          pending: false,
          pendingColName: [],
          company: obj.Company,
        };
        this.cartsList.push(value);
      });
      this.cartsList = this.cartsList.slice(0);
      this.changeRef.detectChanges();
      this.createTableConfiguration(this.cartsList);
    }

    if (this.storageService.userProfileData === 'access') this.getPendingData();
  }

  getPendingData() {
    this.pendingFlag = false;
    let pending;
    this.userProfileService.pendingData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.cartsListPending = data;
        if (this.cartsList?.length > 0) {
          this.cartsList.map((item) => {
            if (item?.legalEntityId === this.cartsListPending?.legalEntityId) {
              this.pendingFlag = true;
              if (this.cartsListPending.customerAdmin === true) {
                item.customerAdmin = true;
                item.pending = true;
                item.pendingColName.push('customerAdmin');
              }
              if (this.cartsListPending.purchaser === true) {
                item.approver = true;
                item.pending = true;
                item.pendingColName.push('approver');
              }
              if (this.cartsListPending.requester === true) {
                item.requester = true;
                item.pending = true;
                item.pendingColName.push('requester');
              }
              if (this.cartsListPending.salesRep === true) {
                item.salesRep = true;
                item.pending = true;
                item.pendingColName.push('salesRep');
              }
            }
          });
        }
        if (!this.pendingFlag) {
          if (this.cartsListPending.customerAdmin === true) {
            pending = 'customerAdmin';
          }
          if (this.cartsListPending.purchaser === true) {
            pending = 'approver';
          }
          if (this.cartsListPending.requester === true) {
            pending = 'requester';
          }
          if (this.cartsListPending.salesRep === true) {
            pending = 'salesRep';
          }
          let value1 = {
            legalEntityId: this.cartsListPending?.legalEntityId,
            LegalEntity: this.cartsListPending?.legalEntityName,
            requester: this.cartsListPending?.requester,
            customerAdmin: this.cartsListPending?.customerName,
            approver: this.cartsListPending?.purchaser,
            salesRep: this.cartsListPending?.salesRep,
            pending: true,
            pendingColName: [pending],
            company: this.cartsListPending?.company,
          };
          this.cartsList.push(value1);
        }
      });
  }

  getCustomerAdminData(data: any, data1: any) {
    let value1;
    let value2;
    let array1: any = [];
    let array2: any = [];
    this.cartsList = [];
    let updateInternalArray: any = [];
    data.map((obj) => {
      value1 = {
        legalEntityId: obj.uid,
        LegalEntity: obj.name,
        requester: false,
        customerAdmin: false,
        approver: false,
        salesRep: false,
        pending: false,
        pendingColName: [],
        company: obj.company,
      };
      array1.push(value1);
    });
    data1.map((obj1) => {
      value2 = {
        legalEntityId: obj1?.uid,
        LegalEntity: obj1?.name,
        requester: obj1?.Requestor,
        customerAdmin: obj1?.CustomerAdmin,
        approver: obj1?.Purchaser,
        salesRep: obj1?.SalesRep,
        pending: false,
        pendingColName: [],
        company: obj1?.company,
      };
      array2.push(value2);
    });
    if (this.role === 'SGRE Admin' && this.adminToggleFlag && this.internal) {
      this.cartsList = array1;
    } else if (this.role === 'SGRE Admin' && !this.adminToggleFlag && this.internal) {
      array2?.map((obj1) => {
        if (obj1.salesRep === true) {
          updateInternalArray.push(obj1);
        }
      });
      this.cartsList = updateInternalArray;
    } else if (this.role === 'SGRE Admin' && !this.internal) {
      this.cartsList = array2;
    } else if (this.role === 'Customer Admin') {
      let updateArray = array1?.map((obj1) => {
        let obj2 = array2?.find(
          (obj2) => obj2.legalEntityId === obj1.legalEntityId
        );
        return obj2 ? obj2 : obj1;
      });
      this.cartsList = updateArray;
    } else {
      this.cartsList = array1;
    }
    if (this.storageService.userProfileData === 'access') {
      this.getPendingData();
    }
    this.getData();
    this.userProfileService.toggleRequest$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.isShowCheckBox = value;
        if (!this.isShowCheckBox) {
          this.cartsList = this.cartsList?.filter((obj) => !obj.newObj);
          this.userProfileService.updateArrayData([]);
        }
      });
    this.createTableConfiguration(this.cartsList);
  }

  roleCheck(roles: string[] | string) {
    if (roles?.length > 0) {
      let rolesArr = typeof roles === 'string' ? JSON.parse(roles) : roles;
      this.userRolesSGRE = rolesArr.some((val) => val === 'SGRE Admin');
      this.userRolesCustomer = rolesArr.some((val) => val === 'Customer Admin');
    }
  }

  createTableConfiguration(data: any) {
    const legalentityFilter = this.addArrayFilter(data, 'LegalEntity');
    const companyFilter = this.addArrayFilter(data, 'company');
    let columnData = [
      {
        label: 'Legal Entity',
        name: 'LegalEntity',
        type: ColumnType.text,
        filter: true,
        filterOptions: legalentityFilter?.map((item) => {
          return { LegalEntity: item };
        }),
      },
      {
        label: 'Requester',
        name: 'requester',
        type: ColumnType.tick2,
      },
      {
        label: 'Purchaser',
        name: 'approver',
        type: ColumnType.tick2,
      },
      {
        label: 'Customer Admin',
        name: 'customerAdmin',
        type: ColumnType.tick2,
      },
    ];
    let columnData1 = [
      {
        label: 'Company',
        name: 'company',
        type: ColumnType.text,
        filter: true,
        filterOptions: companyFilter?.map((item) => {
          return { company: item };
        }),
      },
      {
        label: 'Legal Entity',
        name: 'LegalEntity',
        type: ColumnType.text,
        filter: true,
        filterOptions: legalentityFilter?.map((item) => {
          return { LegalEntity: item };
        }),
      },
      {
        label: 'Sales Rep',
        name: 'salesRep',
        type: ColumnType.tick2,
        style: 'large',
      },
    ];
    this.columns = columnData.map((item) => new Column(item));
    this.columns1 = columnData1.map((item) => new Column(item));
    this.globalService.loadingSubject.next(false);
    this.changeRef.detectChanges();
  }

  editData(edit?: string) {
    this.revokAllFlag = true;
    this.toggleRequest = true;
    this.isShowCheckBox = true;
    this.cartsList = this.cartsList.map((item) =>
      this.userProfileService.mapData(item, this.toggleRequest)
    );
    this.getData();
  }

  addArrayFilter(testArrayData, filterKey) {
    let array = testArrayData?.filter((obj, pos, arr) => {
      return (
        arr.map((mapObj) => mapObj[filterKey]).indexOf(obj[filterKey]) === pos
      );
    });
    return array
      ?.filter((item) => {
        return item[filterKey] !== undefined;
      })
      .map((c) => c[filterKey]);
  }

  public addEntity() {
    this.arrayFlag1 = false;
    let data;
    let name;
    let company;
    if (this.role === 'SGRE Admin') {
      data = {
        legalEntity: name,
        company: company,
        SalesRep: true,
      };
    } else {
      data = {
        legalEntity: name,
        Requester: false,
        Purchaser: false,
        CustomerAdmin: false,
      };
    }
    this.cartsList = this.cartsList.splice(0);
    this.cartsList.push(data);
    this.changeRef.detectChanges();
    this.createTableConfiguration(this.cartsList);
  }

  cancelData() {
    this.revokAllFlag = false;
    this.toggleRequest = false;
    this.userProfileService.setSelected(this.toggleRequest, this.cartsList);
  }

  acceptRole() {
    let sgreAdmin;
    if (this.userRolesSGRE === true && this.internal) {
      sgreAdmin = this.adminToggleFlag;
    } else if (this.userRolesSGRE === true && !this.internal) {
      sgreAdmin = true;
    } else {
      sgreAdmin = null;
    }
    let val = {
      userid: this.user,
      legalEntity: this.legalEntity,
      date: this.date,
      sgreAdmin: sgreAdmin,
    };
    let requestObj: any = [];
    if (this.formArrayVal.length > 0) {
      this.formArrayVal.map((obj) => {
        requestObj = {
          legalEntityId: obj.LegalEntityId,
          requester: obj.requester,
          customerAdmin: obj.customerAdmin,
          approver: obj.approver,
          salesRep: obj.salesRep,
        };
        this.requestArray.push(requestObj);
      });
    } else {
      if (this.internal) {
        this.cartsList.map((obj1) => {
          requestObj = {
            legalEntityId: obj1.legalEntityId,
            requester: false,
            customerAdmin: false,
            approver: false,
            salesRep: obj1.salesRep,
          };
          this.requestArray.push(requestObj);
        });
      } else {
        this.cartsList.map((obj1) => {
          requestObj = {
            legalEntityId: obj1.legalEntityId,
            requester: obj1.requester,
            customerAdmin: obj1.customerAdmin,
            approver: obj1.approver,
            salesRep: false,
          };
          this.requestArray.push(requestObj);
        });
      }
    }
    this.globalService.loadingSubject.next(true);
    this.apiService.acceptAccessRequest(val, this.internal, this.requestArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (val: any) => {
          this.changeIndex();
          // this.globalService.loadingSubject.next(false);
          this.router.navigate([AppConstants.routeUrls.accessRequest]);
        },
        error: (err) => {
          console.error('Observable emitted an error: ' + err),
            this.globalService.loadingSubject.next(false);
        },
      });
  }

  changeIndex() {
    const userLegalEntities = this.storageService.userLegalEntities;
    const changedIndex = userLegalEntities.findIndex(
      (entity) => entity.uid === this.legalEntity
    );
    this.globalService.updateLegalEntity(changedIndex);
  }

  rejectRole() {
    let val = {
      userid: this.user,
      legalEntity: this.legalEntity,
      date: this.date,
    };
    this.globalService.loadingSubject.next(true);
    this.apiService
      .RejectAccessRequest(val)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (val: any) => {
          this.globalService.loadingSubject.next(false);
          this.router.navigate([AppConstants.routeUrls.accessRequest]);
        },
        error: (err) => {
          console.error('Observable emitted an error: ' + err),
            this.globalService.loadingSubject.next(false);
        },
      });
  }

  goBack() {
    this.router.navigate([AppConstants.routeUrls.accessRequest]);
  }

  getsessionvalues() {
    let userRole: any = '';
    userRole = this.storageService.userRoles;
    if (userRole?.includes('Customer Admin')) {
      this.role = 'Customer Admin';
    } else if (userRole?.includes('SGRE Admin')) {
      this.role = 'SGRE Admin';
    }
  }

  // Need to verify the usage of below-method and remove
  getInviteData() {
    const requestObject: any = {};
    requestObject.currentPage = 0;
    requestObject.fields = 'FULL';
    requestObject.pageSize = 200;
    requestObject.userID = this.globalService.getUserId();
    requestObject.getPendingAccessRoles = true;
    requestObject.getCustomerDetails = true;

    this.userProfileService.getLegalEntityList(requestObject)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (value: any) => {
          this.legalentities = value.legalEntities;
        },
        error: (err) => console.error('Observable emitted an error: ' + err),
      });
  }

  public updateCheckBtnClick(data) {
    let val;
    let selectedArray = data.formArray[data.index];
    this.cartsList.map((obj1) => {
      if (selectedArray.LegalEntity === obj1.LegalEntity) {
        if (this.internal === true) {
          val = {
            LegalEntityId: obj1.legalEntityId,
            LegalEntity: selectedArray.LegalEntity,
            requester: false,
            customerAdmin: false,
            approver: false,
            salesRep: selectedArray.salesRep,
          };
        } else {
          val = {
            LegalEntityId: obj1.legalEntityId,
            LegalEntity: selectedArray.LegalEntity,
            requester: selectedArray.requester,
            customerAdmin: selectedArray.customerAdmin,
            approver: selectedArray.approver,
            salesRep: false,
          };
        }
      }
    });
    for (let i = 0; i < this.formArrayVal.length; i++) {
      if (this.formArrayVal[i].LegalEntityId === val.LegalEntityId)
        this.formArrayVal.splice(i, 1);
    }
    this.formArrayVal.push(val);
  }

  confirmAction(key: any) {
    this.requestArray = [];
    let sgreAdmin;
    if (this.internal) {
      sgreAdmin = this.adminToggleFlag;
    } else {
      sgreAdmin = null;
    }
    let requestObj: any = [];
    if (key === 'save') {
      if (this.formArrayVal.length > 0) {
        this.formArrayVal.map((obj) => {
          requestObj = {
            legalEntityId: obj.LegalEntityId,
            requester: obj.requester,
            customerAdmin: obj.customerAdmin,
            approver: obj.approver,
            salesRep: obj.salesRep,
          };
          this.requestArray.push(requestObj);
        });
      } else {
        this.requestArray = [];
      }
    } else {
      this.cartsList.map((obj) => {
        requestObj = {
          legalEntityId: obj.legalEntityId,
          requester: false,
          customerAdmin: false,
          approver: false,
          salesRep: false,
        };
        this.requestArray.push(requestObj);
      });
      if (this.internal) sgreAdmin = false;
    }
    if (this.internal || (this.requestArray.length > 0 && !this.internal)) {
      this.globalService.loadingSubject.next(true);
      this.apiService.updateCustomer(this.user, sgreAdmin, this.internal, this.requestArray)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (val: any) => {
            this.revokAllFlag = false;
            this.toggleRequest = false;
            this.userProfileService.fetchUserProfile(this.globalService.getUserId(), this.user);
            this.userProfileService.setSelected(this.toggleRequest, this.cartsList);
            this.formArrayVal = [];
            this.getUserProfileData();
            this.globalService.loadingSubject.next(false);
          },
          error: (err) => {
            this.globalService.loadingSubject.next(false);
            console.error('Observable emitted an error: ' + err);
          },
        });
    }
  }

  toggleChangevalue() {
    if (this.adminToggleFlag === true) this.adminToggleFlag = false;
    else this.adminToggleFlag = true;
  }

  addNewEntryToTable() {
    let updatedData: any = [];
    if (this.selectedCity.uid) {
      let data = {
        legalEntityId: this.selectedCity.uid,
        LegalEntity: this.selectedCity.name,
        company: this.selectedCity.company,
        requester: false,
        customerAdmin: false,
        approver: false,
        salesRep: false,
      };
      updatedData = { ...data, newObj: true };
      if (this.formArrayVal.length > 0) {
        for (let i = 0; i < this.cartsList.length; i++) {
          for (let j = 0; j < this.formArrayVal.length; j++) {
            if (
              this.cartsList[i].legalEntityId ===
              this.formArrayVal[j].LegalEntityId
            ) {
              this.cartsList[i] = this.formArrayVal[j];
            }
          }
        }
      }
      this.cartsList.push(updatedData);
      this.cartsList = this.cartsList.splice(0);
      this.entityList = this.entityList?.filter((item) => {
        return item.name !== this.selectedCity?.name;
      });
      this.createTableConfiguration(this.cartsList);
      this.changeRef.detectChanges();
      this.selectedCity = '';
    }
  }

  getData() {
    let updateArray = this.loginData
      .filter((item) => !this.cartsList.some((itemToBeRemoved) => itemToBeRemoved.legalEntityId === item.uid))
      .sort(this.globalService.sortDropdownOptions);
    this.entityList = updateArray;
  }

  downloadCSV() {
    let rows;
    let headers;
    let selectedLanguage;
    let showFlag = false;
    let tableData = this.filteredTableData.length > 0 ? this.filteredTableData : this.cartsList
    if (!this.internal) {
      headers = [
        'LegalEntityId',
        'LegalEntityName',
        'Requester',
        'CustomerAdmin',
        'Purchaser',
        'Company',
      ];
      rows = tableData.map(row => [row?.legalEntityId ? row.legalEntityId : '-', row?.LegalEntity ? row.LegalEntity : '-', row?.requester.toString(), row?.customerAdmin.toString(), row?.approver.toString(), row.company ? row.company : '-']);
    }
    else {
      headers = [
        'LegalEntityId',
        'LegalEntityName',
        'SalesRep',
        'Company',];
      rows = tableData.map(row => [row?.legalEntityId, row?.LegalEntity ? row.LegalEntity : '-', row?.salesRep.toString(), row.company ? row.company : '-']);
    }
    this.languageService.getActive()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((lang) => {
        selectedLanguage = lang;
        const translatedArray = this.translateArray(headers, selectedLanguage);
        headers = translatedArray;
        showFlag = true;
      });
    if (showFlag) {
      const data1 = [headers, ...rows];
      const exportOptions: ExportFileOptions = {
        fileName: 'User Profile',
        extension: 'csv',
        type: 'text/csv'
      }
      const separator = ';'
      this.csvExportService.download(data1, separator, exportOptions);
      showFlag = false
    }
  }

  onFilter(data) {
    this.filteredTableData = data.filteredData;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
