import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
// import { saveAs } from 'file-saver';
import { BulkOrderService } from '../../services/bulk-order-service';
import { Observable } from 'rxjs';
// import * as XLSX from 'xlsx';
// import * as FileSaver from 'file-saver';
import { ExcelService } from '../../services/excel.service';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';

@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrl: './download-file.component.scss'
})
export class DownloadFileComponent {

  array: any = [];
  currentpage$: Observable<number>
  public url: string;
  public componentData: any;
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    // showTitle: true,
    // title: 'Bulk Order :',
    useBom: true,
    noDownload: false,
    headers: ["Part Number", "Quantity"]
  };

  columns = [
    { header: 'Part Number', key: 'partNumber', },
    { header: 'Quantity', key: 'quantity', },

  ];
  public userRoles: any[];
  role: string;
  constructor(public component: CmsComponentData<any>, public storageService: StorageService, public bulkservice: BulkOrderService, public excelService: ExcelService, private globalService: GlobalService,) { }

  ngOnInit(): void {
    this.currentpage$ = this.bulkservice.isNext$;
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
    this.getUserRole();
    this.globalService.recreate(false);
  }

  exportToExcel(): void {
    this.excelService.generateExcel(this.columns, 'Bulk_order');
  }

  remove(i: any) {
    this.array.splice(i, 1);
  }

  uploadImage(event: any) {
    let file: any = event.currentTarget.files;
    this.array.push(event.currentTarget.files[0]);
  }

  download() {
    var blob = new Blob(["Hello, world!"], { type: "application/vnd.ms-excel;charset=utf-8" });
    // saveAs(blob, "hello world.txt");
  }

  getUserRole() {
    let userRole: any = ''
    userRole = this.storageService.userRoles;

    if (userRole?.includes("Requester") && !userRole?.includes("Purchaser")) {
      this.role = 'Requester';
    }
    else if (userRole?.includes("Purchaser") && !userRole?.includes("Requester")) {
      this.role = 'Purchaser';
    }
    else if (userRole?.includes("Purchaser") && userRole?.includes("Requester")) {
      this.role = 'Purchaser&Requester'
    }
  }

};